import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, baseWebsite, compile, style} from "./utils";
import useLocalStorage from "./useLocalStorage";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Page from "./Page";
import dayjs from "dayjs";
import BackButton from "./BackButton";
import Latex from 'react-latex'
import TableCell from "@material-ui/core/TableCell";


function ContestPage() {
  const [contest, setContest] = useState({})
  const [exc, setExc] = useState(false)
  const [token, setToken] = useLocalStorage('token', '')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [tick, setTick] = useState(0)
  const [failed, setFailed] = useState(false)
  const [answers, setAnswers] = useState([])
  const [seconds, setSeconds] = useState(0)
  const [showAns, setShowAns] = useState([])
  const id = document.location.href.split('/:')[1]
  const padStyle = {padding: 20}
  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const right = {margin: 'right', textAlign: 'right', align: 'right'}
  const titleStyle = {
    fontSize: 40,
    padding: 20,
  }
  const problemStyle = {
    fontSize: 24,
    fontFamily: 'serif'
  }
  const answerStyle = {fontSize: 24, height: 30, textAlign: 'center'}
  const large = {fontSize: 25}
  const nameStyle = {fontSize: 32, fontFamily: 'serif'}

  function unixToStr(timestamp) {
    return (new Date(1000 * timestamp)).toLocaleString()
  }

  useEffect(() => {
    async function getContest() {
      if (!exc) {
        const result = (await axios.post(`${baseServer}/contests/getContest`, {contestId: id}, {headers: {token: token}})).data
        setContest(result)
        if (tick === 0 && result.problems) {
          let blankArr = []
          console.log(result.problems)
          result.problems.forEach(elem => {
            blankArr.push(false)
          })
          setShowAns(blankArr)
        }
      }
    }

    getContest()
    setTimeout(() => {
      setTick(tick + 1)
    }, 1000)
  }, [tick])


  return (
    <Page>
      <BackButton/>
      <div style={titleStyle}>
        {contest.title}
      </div>
      <div style={center}>
        <div style={nameStyle}>
          {contest.team ? contest.team.name : null}
        </div>
        {(contest.time + (contest.minutes * 60) < dayjs().unix()) ?
          <div>
            <div style={padStyle}>
              The contest is over, click below to view the final rankings.
            </div>
            <Button onClick={() => {
              document.location = `${baseWebsite}/results/:${id}`
            }}>
              Contest Results
            </Button>
          </div> : null}
      </div>
      {
        !contest.team && contest.title && dayjs().unix() < contest.time + 60 * contest.minutes ?
          <div style={center}>
            <div style={padStyle}>
              <TextField label={'Team Name'} onChange={event => {
                setName(event.target.value)
              }} value={name}/>
            </div>
            <div style={padStyle}>
              <Button variant="contained" style={style} onClick={async () => {
                const res = (await axios.post(`${baseServer}/contests/createTeam`, {
                  contestId: id,
                  teamName: name
                }, {headers: {token: token}})).data
                if (res) {
                  window.location.reload()
                } else {
                  setFailed(true)
                }
              }}>
                Create Team
              </Button>
              <div>
                {failed ? 'This team name is taken' : null}
              </div>
            </div>
            <Divider/>
            <div style={padStyle}>
              <TextField label={'Enter Team Code'} onChange={event => {
                setCode(event.target.value)
              }} value={code}/>
            </div>
            <div style={padStyle}>
              <Button variant="contained" style={style} onClick={async () => {
                const res = await axios.post(`${baseServer}/contests/joinTeam`, {
                  code: code,
                  contestId: id
                }, {headers: {token: token}})
                if (res.data === 'limit') {
                  console.log('too many ')
                  setContest({title: 'Team Size Limit Exceeded'})
                  setExc(true)
                } else {
                  window.location.reload()
                }
              }}>
                Join Team
              </Button>
            </div>
          </div>
          : contest.problems && dayjs().unix() > contest.time + 60 * contest.minutes ?
            <div style={center}>
              <div style={{padding:20}}>
                <Button variant="contained" style={style} onClick={() => {
                  document.location = `${baseWebsite}/pdf/:${id}`
                }}>
                  Print Problems
                </Button>
              </div>
              {contest.problems.map((elem, idx) =>
                <div>
                  {elem.text ? <div style={padStyle}>
                    <Divider/>
                    <div style={padStyle}>
                      <div style={problemStyle}>
                        {compile(idx + 1 + '. ' + elem.text, {})}
                        <div style={padStyle}>
                          {elem.img ? <img style={{height: 300, width: 500}} src={elem.img}
                                           alt="preview"/> : null}
                        </div>
                        <Button style={style} onClick={() => {
                          let temp = JSON.parse(JSON.stringify(showAns))
                          temp[idx] = !temp[idx]
                          setShowAns(temp)
                        }}>
                          {showAns[idx] ? 'Hide Answer' : 'Show Answer'}
                        </Button>
                        <div>
                          {showAns[idx] ? compile('$' + elem.answer.ans + '$') : null}
                        </div>
                        <div>
                          {showAns[idx] ? compile(elem.answer.sol) : null}
                        </div>
                      </div>
                    </div>
                  </div> : null}
                </div>
              )}
            </div>
            : contest.team ? <div style={center}>
              {!contest.problems && (contest.time > dayjs().unix()) ?
                <div>
                  <div style={large}>
                    Your Team Code: {contest.team.code}
                  </div>
                  <div style={padStyle}>
                    <div>
                      Contest will begin {unixToStr(contest.time)}
                    </div>
                  </div>
                </div>
                : contest.problems ?
                  <div>
                    <div style={{padding:20}}>
                      <Button variant="contained" style={style} onClick={() => {
                        document.location = `${baseWebsite}/pdf/:${id}`
                      }}>
                        Print Problems
                      </Button>
                    </div>
                    {dayjs().unix() < contest.date + 60 * contest.minutes ?
                      <div style={padStyle}>
                        <div style={right}>
                          Time Remaining: {' '}
                          {' ' + Math.floor((contest.time + (contest.minutes * 60) - dayjs().unix()) / 3600) > 0 ? Math.floor((contest.time + (contest.minutes * 60) - dayjs().unix()) / 3600) + 'Hours' : null}
                          {' ' + Math.floor((contest.time + (contest.minutes * 60) - dayjs().unix()) / 60)} Minutes
                          {' ' + (contest.time + (contest.minutes * 60) - dayjs().unix()) % 60} Seconds
                        </div>
                      </div> : null}
                    {contest.problems.map((elem, idx) =>
                      <div>
                        {elem.text ? <div style={padStyle}>
                          <Divider/>
                          <div style={padStyle}>
                            <div style={problemStyle}>
                              {compile(idx + 1 + '. ' + elem.text, {})}
                              <div style={padStyle}>
                                {elem.img ?
                                  <img style={{height: 300, width: 500}} src={elem.img}
                                       alt="preview"/> : null}
                              </div>
                            </div>
                            <div style={padStyle}>
                              {dayjs().unix() < contest.time + 60 * contest.minutes ? <div>
                                {contest.type === 2 && idx > 0 ?
                                  <div style={padStyle}>
                                    Previous Answer:
                                    <span>{' '}</span>
                                    {elem.prevAnswer ?
                                      <Latex style={{fontFamily: 'sans-serif'}}>
                                        {'$' + elem.prevAnswer + '$'}
                                      </Latex> : 'None'}
                                  </div> : null
                                }
                                {!contest.type || contest.type === 2 ?
                                  <span style={padStyle}>
                                  Current Answer:
                                  <span>{' '}</span>
                                  <Latex style={{fontFamily: 'sans-serif'}}>
                            {contest.team.answers[idx] !== null ? '$' + contest.team.answers[idx] + '$' : 'None'}
                                  </Latex>
                          </span> : null
                                }
                                <TextField style={answerStyle} variant="outlined"
                                           onChange={event => {
                                             const answersCopy = [...answers]
                                             answersCopy[idx] = event.target.value
                                             console.log(answersCopy)
                                             setAnswers(answersCopy)
                                           }}/>
                                <span style={padStyle}>
                          <Button variant="contained" style={style} onClick={async () => {
                            await axios.post(`${baseServer}/contests/submitAnswer`, {
                              contestId: id,
                              problemIdx: idx,
                              answer: answers[idx]
                            }, {headers: {token: token}})
                          }}>
                            Submit
                          </Button>
                        </span>
                              </div> : null}
                            </div>
                          </div>
                        </div> : null}
                      </div>
                    )}
                  </div> : null
              }
            </div> : (contest.time + (contest.minutes * 60) < dayjs().unix()) ?
              <div>
                <div style={padStyle}>
                  The contest is over, click below to view the final rankings.
                </div>
                <Button onClick={() => {
                  document.location = `${baseWebsite}/results/:${id}`
                }}>
                  Contest Results
                </Button>
              </div> : null
      }
    </Page>
  )

}

export default ContestPage
