import {HashRouter as Router, Route} from "react-router-dom";
import React from "react";
import Login from "./Login";
import Signup from "./Signup";
import NewProblem from "./NewProblem";
import MyProblems from "./MyProblems";
import ViewProblem from "./ViewProblem";
import PublicProblems from "./PublicProblems";
import CreateContest from "./CreateContest";
import AllContests from "./AllContests";
import ContestPage from "./ContestPage";
import Results from "./Results";
import AllSeries from "./AllSeries";
import CreateSeries from "./CreateSeries";
import SeriesPage from "./SeriesPage";
import SeriesResults from "./SeriesResults";
import Info from "./Info";
import Access from "./Access";
import EmailAuth from "./EmailAuth";
import Forgot from "./Forgot";
import ChangePass from "./ChangePass";
import Profile from "./Profile";
import GradingDashboard from "./GradingDashboard";
import TeamAnswers from "./TeamAnswers";
import EditContest from "./EditContest";
import ContestPdf from "./ContestPdf";

function Main() {
  return (
    <Router>
      <Route path={'/viewProblem'}>
        <ViewProblem/>
      </Route>
      <Route exact path={'/'}>
        <MyProblems/>
      </Route>
      <Route exact path={'/forgot/'}>
        <Forgot/>
      </Route>
      <Route path={'/changePass/'}>
        <ChangePass/>
      </Route>
      <Route path={'/signedUp/'}>
        <div>Check Email for verification instructions,
          make sure to check the spam folder
        </div>
      </Route>
      <Route path={'/email/'}>
        <EmailAuth/>
      </Route>
      <Route exact path={'/access'}>
        <Access/>
      </Route>
      <Route exact path={'/login/'}>
        <Login/>
      </Route>
      <Route exact path={'/signup/'}>
        <Signup/>
      </Route>
      <Route exact path={'/new/'}>
        <NewProblem/>
      </Route>
      <Route exact path={'/public/'}>
        <PublicProblems/>
      </Route>
      <Route exact path={'/profile/'}>
        <Profile/>
      </Route>
      <Route exact path={'/create/'}>
        <CreateContest/>
      </Route>
      <Route exact path={'/createSeries/'}>
        <CreateSeries/>
      </Route>
      <Route exact path={'/allContests/'}>
        <AllContests/>
      </Route>
      <Route path={'/allSeries/'}>
        <AllSeries/>
      </Route>
      <Route path={'/contest/'}>
        <ContestPage/>
      </Route>
      <Route path={'/series/'}>
        <SeriesPage/>
      </Route>
      <Route path={'/results/'}>
        <Results/>
      </Route>
      <Route path={'/seriesResults/'}>
        <SeriesResults/>
      </Route>
      <Route exact path={'/info/'}>
        <Info/>
      </Route>
      <Route path={'/grading/'}>
        <GradingDashboard/>
      </Route>
      <Route path={'/teamAnswers/'}>
        <TeamAnswers/>
      </Route>
      <Route path={'/editContest/'}>
        <EditContest/>
      </Route>
      <Route path={'/pdf/'}>
        <ContestPdf/>
      </Route>
    </Router>
  );
}

export default Main;
