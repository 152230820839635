import React, {useEffect, useState} from "react";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";
import {baseServer, compile} from "./utils";
import {Table, TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BackButton from "./BackButton";
import Page from "./Page";
import Button from "@material-ui/core/Button";

function TeamAnswers() {
  const [current, setCurrent] = useState({})
  const [token, setToken] = useLocalStorage('token')
  const split = url => url.split('/:')
  const splitUrl = split(document.location.href)
  const contestID = splitUrl[1]
  const [teamName,setTeamName] = useLocalStorage('team')

  useEffect(async () => {
    const stuff = await axios.post(`${baseServer}/contests/teamAnswers`, {contestID: contestID, teamName: teamName},
      {
        headers:
          {token: token}
      })
    setCurrent(stuff.data)
  }, [])
  const titleStyle = {
    margin: 'auto',
    textAlign: 'center',
    fontSize:30,
    padding:20
  }
  return (<Page>
      <BackButton/>
      <div style={titleStyle}>
        {teamName}
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>
              Team's Answer
            </TableCell>
            <TableCell>
              Correct Answer
            </TableCell>
            <TableCell>
              Marked
            </TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {current.results ?
            current.results.map((elem, idx) => (
              <TableRow>
                <TableCell>{idx+1}.</TableCell>
                <TableCell>
                  {compile('$'+current.answers[idx]+'$',{fontFamily: 'serif'})}
                </TableCell>
                <TableCell>
                  {compile('$'+current.correctAnswers[idx]+'$',{fontFamily: 'serif'})}
                </TableCell>
                <TableCell>
                  {elem ? 'Correct' : 'Wrong'}
                </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={async() => {
                    await axios.post(`${baseServer}/contests/addManualGrade`,{
                      contestID:contestID,
                      teamName:teamName,
                      grade:!elem,
                      problemNum:idx},
                      {headers:{token:token}})
                    const res = [...current.results]
                    res[idx] = !elem
                    console.log(res)
                    const currentCopy = {answers:current.answers,correctAnswers:current.correctAnswers,results:res}
                    setCurrent(currentCopy)
                  }}>
                    {elem?'Mark Wrong':'Mark Correct'}
                  </Button>
                </TableCell>
              </TableRow>
            )) : null
          }
        </TableBody>
      </Table>
    </Page>
  )
}

export default TeamAnswers
