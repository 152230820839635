import React, {useEffect} from "react";
import useLocalStorage from "./useLocalStorage";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import {baseWebsite, style} from "./utils";
import Toolbar from "@material-ui/core/Toolbar";

export default function Header() {
    const [user, setUser] = useLocalStorage('user', '')
    const [token, setToken] = useLocalStorage('token', '')
    const buttonStyle = {width: '14%'}
    useEffect(() => {
        if (!token.length) {
            document.location = `${baseWebsite}/login`
        }
    }, [])
    return (
        <div>
            <AppBar style={style} position="static">
                <Toolbar>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/profile`
                    }}>
                       Profile
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = baseWebsite
                    }}>
                        My Collection
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/allContests`
                    }}>
                        Contests
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/allSeries`
                    }}>
                        Contest Series
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/public/`
                    }}>
                        Public Problems
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/info/`
                    }}>
                        Info
                    </Button>
                    <Button color='inherit' style={buttonStyle} onClick={() => {
                        document.location = `${baseWebsite}/login/`
                        setToken('')
                        setUser('')
                    }}>
                        Logout
                    </Button>
                    {user}
                </Toolbar>
            </AppBar>
        </div>
    )
}
