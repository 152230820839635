import React, {Component} from 'react';
import {render} from 'react-dom';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {center} from "./utils";
import Fab from "@material-ui/core/Fab";

const SortableItem = SortableElement(({value}) =>
  <Grid style={padStyle}>
    <Paper style={paperStyle}>
      {value.index + 1}. {value.value.title}, {value.value.weight} Points
    </Paper>
  </Grid>
);
const padStyle = {
  padding: 20
}

const paperStyle = {
  width: 500, height: 50, margin: 'auto',
  textAlign: 'center',
  align: 'center', fontSize: 20
}
const SortableList = SortableContainer(({items,destroy}) => {
  return (
    <ul>
      <div className="row" style={{...center,display: 'flex',width:'50%'}}>
        <div className="column" style={{float:'left',flex:'10%',width:'0%'}}>
          {items.map((value, index) => (
            <SortableItem key={`item-${value}`}
                          value={{value: value, index: index}} index={index}/>
          ))}
        </div>
        <div className="column" style={{float:'left',flex:'10%',width:'0%'}}>
          {items.map((value,index) => (
            <div style={{height:90}}>
              <Fab onClick={()=>{destroy(index)}}>
                X
              </Fab>
            </div>
          ))}
        </div>
      </div>
    </ul>
  );
});

export default function Sortable({items, setItems, destroy}) {

  return <SortableList items={items}
                       destroy={destroy}
                       onSortEnd={({oldIndex, newIndex}) => {
                         setItems(arrayMoveImmutable(items, oldIndex, newIndex));
                       }}/>;
}
