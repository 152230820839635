import React from "react";
import Header from "./Header";
function Page({children,style}) {
  return (
    <div>
      <Header/>
      <div style={style}>
        {children}
      </div>
    </div>
  )
}

export default Page