import Page from "./Page";
import {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";

function EmailAuth() {
  const code = document.location.href.split('/:')[2]
  const email = document.location.href.split('/:')[1]
  const [token, setToken] = useLocalStorage('token')
  const [user,setUser] = useLocalStorage('user')
  const [id,setId] = useLocalStorage('id','' )
  useEffect(async ()=>{
    const res = (await axios.post(`${baseServer}/user/verify`,{email:email,code:code})).data
    if (res.token) {
      setToken(res.token)
      setUser(res.username)
      setId()
      document.location = baseWebsite
    }
  },[])
  return <div/>
}

export default EmailAuth
