import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, compile, style} from "./utils";
import useLocalStorage from "./useLocalStorage";
import BackButton from "./BackButton";
import Page from "./Page";
import Button from "@material-ui/core/Button";
import Latex from "react-latex";
import {ThumbUp} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";

function ViewProblem() {
  const getId = url => url.split('/:')[1]
  const [data, setData] = useState({})
  const [token, setToken] = useLocalStorage('token', '')
  const [showAns, setShowAns] = useState(false)
  const titleStyle = {
    fontSize: 40,
    padding: 20,
    fontFamily: 'serif'
  }
  const imgStyle = {
    height: 400,
    width: 600
  }
  const padStyle = {
    padding: 20
  }
  const probStyle = {
    fontSize: 20,
    padding: 40,
    fontFamily: 'serif'
  }

  useEffect(() => {
    async function getProblem() {
      const prob = (await axios.post(`${baseServer}/problems/getProblem`, {id: getId(document.location.href)}, {headers: {token: token}})).data
      setData(prob)
    }

    getProblem()
  }, [])
  return (
    <Page>
      <BackButton/>
      <div style={titleStyle}>
        {data.title}
      </div>
      <div>
        {data ?
          compile(data.text, probStyle)
          : null}
      </div>
      <div style={padStyle}>
        {data.img ? <img style={imgStyle} src={data.img} alt="problem image"/> : null}
      </div>
      {data.likable ?
        <div>
      <span style={{padding: 10}}>
        <Fab onClick={async () => {
          await axios.post(`${baseServer}/problems/changeLikeStatus`,
            {id: getId(document.location.href)},
            {headers: {token: token}}
          )
          if (data.liked) {
            data.likeCount--
            setData({...data, liked: false})
          } else {
            data.likeCount++
            setData({...data, liked: true})
          }
        }}>
          <ThumbUp style={{color: data.liked ? '#fff' : '#000'}}></ThumbUp>
        </Fab>
      </span>
          <span style={{fontSize: 20}}>
        {data.likeCount}
      </span>
        </div> : data.likeCount>0?
        <div>
          <span style={{fontSize: 20,padding:30}}>
            {data.likeCount} {data.likeCount===1?'user':'users'} {data.likeCount===1?'has':'have'} liked this problem
          </span>
        </div>:null
      }
      <div style={{padding: 20}}>
        <Button variant="contained" style={style} onClick={() => {
          setShowAns(!showAns)
        }}>
          {showAns ? 'Hide Answer' : 'Show Answer'}
        </Button>
      </div>
      {showAns ?
        <div style={{padding: 50, fontSize: 20}}>
          <Latex style={{fontFamily: 'sans-serif', padding: 50, fontSize: 20}}>
            {'$' + data.answer + '$'}
          </Latex>
          {compile(data.sol, probStyle)}
        </div> : null}

      <div style={{padding: 10}}>
      <span>
          <img style={{width: 50, height: 50}} src={data && data.pfp ? data.pfp : 'https://i.imgur.com/6128x37.jpeg'}
               alt=""/>
      </span>

        <div style={{fontFamily: 'serif', fontSize: 20}}>
          {data.username}
        </div>
      </div>
    </Page>
  )
}

export default ViewProblem
