import React, {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import Page from "./Page";
import PfpDialog from "./PfpDialog";
import axios from "axios";
import {baseServer, style} from "./utils";
import ReqDialog from "./ReqDialog";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";

function Profile() {
  const [token, setToken] = useLocalStorage('token')
  const [username, setUsername] = useLocalStorage('user')
  const [pfp, setPfp] = useLocalStorage('')
  const [friends, setFriends] = useState([])
  const [incoming, setIncoming] = useState([])
  const [outgoing, setOutgoing] = useState([])
  const [reqOpen, setReqOpen] = useState(false)
  const imgStyle = {
    height: 100,
    width: 100
  }
  const row = {
    display: 'table',
    clear: 'both',
    boxSizing: 'border-box'
  }
  const col = {
    float: 'left',
    width: window.innerWidth * 0.23,
    padding: 20,
    boxSizing: 'border-box'
  }

  useEffect(async () => {
    const res = (await axios.get(`${baseServer}/social/profile`, {headers: {token: token}})).data
    setPfp(res.pfp)
    setFriends(res.friends)
    setOutgoing(res.outgoing)
    setIncoming(res.incoming)
  }, [])
  return (
    <Page>
      <ReqDialog open={reqOpen} onClose={() => {
        setReqOpen(false)
        window.location.reload()
      }}/>
      <div className="row" style={row}>
        <div className="column" style={{...col, padding: 30}}>
          <div>
            <img style={imgStyle} src={pfp?pfp:'https://i.imgur.com/6128x37.jpeg'} alt={''}/>
          </div>
          <div style={{fontSize: 30}}>
            {username}
          </div>
        </div>
        <div className="column" style={col}>
          <Button style={style} onClick={() => {
            setReqOpen(true)
          }}>
            Request Friends
          </Button>
          <div style={{padding: 15}}>Friends ({friends.length})</div>
          {friends.map(elem => <div style={{padding: 15}}>{elem}</div>)}
        </div>
        <div className="column" style={col}>
          <div>Outgoing Requests ({outgoing.length})</div>
          {outgoing.map(elem => <div style={{padding: 15}}>{elem}</div>)}
        </div>
        <div className="column" style={col}>
          <div>Incoming Requests ({incoming.length})</div>
          {incoming.map(elem =>
            <div style={{padding: 15}}>
              <span style={{padding: 15}}>{elem.username}
              </span>
              <span style={{padding: 15}}>
              <Fab style={style} onClick={async () => {
                console.log(elem.id)
                await axios.post(`${baseServer}/social/confirm`, {id: elem.id},
                  {headers: {token: token}})
                window.location.reload()
              }}>
                ✓
              </Fab>
                </span>
                <Fab style={style} onClick={async () => {
                  console.log(elem.id)
                  await axios.post(`${baseServer}/social/decline`, {id: elem.id},
                    {headers: {token: token}})
                  window.location.reload()
                }}>
                x
              </Fab>

            </div>)}
        </div>
      </div>
    </Page>
  )
}

export default Profile
