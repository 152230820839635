import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import useLocalStorage from "./useLocalStorage";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import NewProblem from "./NewProblem";
import Edit from "./Edit";

function MyProblems() {
    const [problems, setProblems] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [token, setToken] = useLocalStorage('token', '')
    const [id, setId] = useState('')
    const [couldntDelete, setCouldntDelete] = useState(false)
    useEffect(() => {
        async function getProblems() {
            const newProblems = (await axios.post(`${baseServer}/problems/myProblems`, {}, {headers: {token: token}}))
            console.log(newProblems)
            if ((!newProblems.data.error) && newProblems.status === 200) {
                setProblems(newProblems.data)
            } else {
                document.location = `${baseWebsite}/login`
            }
        }

        getProblems()
    }, [])
    const paperStyle = {
        width: 400,
        height: 50
    }
    const deleteStyle = {
        width: 100,
        height: 40,
        ...style
    }
    const padStyle = {
        padding: 20
    }
    const center = {
        margin: 'auto',
        textAlign: 'center',
        align: 'center'
    }
    const plusStyle = {fontSize: 20}
    return (
        <Page>
            <Grid item xs={10} style={center}>
                <Grid container justify="center" spacing={5} style={padStyle}>
                    <div style={padStyle}>
                        <Fab style={plusStyle} color={"#000055"} aria-label="add" onClick={() => {
                            setDialogOpen(true)
                        }}>
                            +
                        </Fab>
                        {problems.filter(elem => elem.privacy && elem.owner).length > 0 ?
                            <div style={{padding: 20}}>
                                <Button color={"#000055"} aria-label="add" onClick={() => {
                                    document.location = `${baseWebsite}/access`
                                }} variant="contained">
                                    Private Problem Access
                                </Button>
                            </div> : null}
                        <Dialog open={dialogOpen} onClose={() => {
                            setDialogOpen(false)
                        }}>
                            <DialogTitle>
                                Write a New Problem
                            </DialogTitle>
                            <NewProblem onClose={() => {
                                setDialogOpen(false)
                            }}/>
                        </Dialog>
                        <Dialog open={editing} onClose={() => {
                            setEditing(false)
                        }}>
                            <DialogTitle>
                                Edit Problem
                            </DialogTitle>
                            <Edit onClose={() => {
                                setDialogOpen(false)
                            }} id={id}/>
                        </Dialog>
                        <Dialog open={couldntDelete} onClose={() => {
                            setCouldntDelete(false)
                        }}>
                            <div style={padStyle}>
                                <DialogTitle>
                                    Couldn't Delete This Problem
                                </DialogTitle>
                                This problem was used in a contest, so it can no longer be deleted.
                            </div>
                        </Dialog>
                    </div>
                    {
                        problems.sort((a, b) => b.date - a.date).map(elem => (
                            <div style={padStyle}>
                                <Grid style={padStyle}>
                                    <Paper style={paperStyle}>
                                        <Button style={paperStyle} onClick={() => {
                                            document.location = `${baseWebsite}/viewProblem/:${elem._id}`
                                        }}>
                                            {elem.title} {new Date(elem.date * 1000).toLocaleString()}
                                        </Button>
                                        {elem.editable ?
                                            <div>
                                                <Button style={deleteStyle} onClick={() => {
                                                    setEditing(true)
                                                    setId(elem._id)
                                                }}>
                                                    Edit
                                                </Button>
                                                <span style={{width: 50}}>
                                             {'            '}
                                        </span>
                                                <Button style={deleteStyle} onClick={async () => {
                                                    const del = (
                                                        await axios.post(`${baseServer}/problems/delete`, {id: elem._id}, {headers: {token: token}}))
                                                        .data
                                                    if (del) {
                                                        window.location.reload()
                                                    } else {
                                                        setCouldntDelete(true)
                                                    }
                                                }}>
                                                    Delete
                                                </Button>
                                            </div> : null}
                                    </Paper>
                                </Grid>
                            </div>
                        ))
                    }
                </Grid>
            </Grid>
        </Page>
    )
}

export default MyProblems
