import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseServer} from "./utils";
import useLocalStorage from "./useLocalStorage";

function CollabList({problem}) {
    const [collabs, setCollabs] = useState([])
    const [token, setToken] = useLocalStorage('token')
    useEffect(async () => {
        console.log(problem._id)
        const col = (await axios.post(`${baseServer}/problems/collabList`, {id: problem._id},
            {headers: {token: token}})).data
        setCollabs(col)
    }, [])
    return (
        <div>
            <div style={{padding: 50}}>
                Editors:
                {collabs.filter(elem => elem.editor).map(elem =>
                    <div style={{padding: 20}}>
                        {elem.username}
                    </div>
                )}
            </div>
            <div style={{padding: 50}}>
                Viewers:
                {collabs.filter(elem => !elem.editor).map(elem =>
                    <div style={{padding: 20}}>
                        {elem.username}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CollabList
