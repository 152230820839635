import React, {useEffect, useState} from "react";
import {Dialog} from "@material-ui/core";
import axios from "axios";
import {baseServer, style} from "./utils";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import useLocalStorage from "./useLocalStorage";

function SeriesShareDialog({onClose, open,series}) {
  const [token,setToken] = useLocalStorage('token')
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState('')
  const center = {
    margin: 'auto',
    textAlign: 'center',
  }
  useEffect(async () => {
    const users = (await axios.post(`${baseServer}/social/findAllUsers`, {search: search},{headers:{token:token}})).data
    setUsers(users)
  }, [search])
  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{padding:15,fontSize:20}}>
        Select a user to request
      </div>
      <div style={center}>
        <div style={{padding: 20}}>
          <TextField variant="outlined" value={search} onChange={event => {
            setSearch(event.target.value)
          }}/>
        </div>
      </div>
      <div style={center}>
        <div style={{width: 300, height: 300}}>
          {users.map(elem =>
            <div style={{padding:10}}>
              <Button style={elem._id === selected ? {...style,width: 250, height: 40} : {width: 250, height: 40}} onClick={() => {
                setSelected(elem._id)
              }}>
                {elem.username}
              </Button>
            </div>
          )}
        </div>
        <div style={{padding:20}}>
          <Button style={style} onClick={async()=>{
            await axios.post(`${baseServer}/series/share`,{userId:selected,seriesId:series},{headers:{token:token}})
            onClose()
          }}>
            Share
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default SeriesShareDialog
