import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Page from "./Page";
import Button from "@material-ui/core/Button";
import jwt from 'jsonwebtoken'
import useLocalStorage from "./useLocalStorage";
import ChangeTime from "./ChangeTime";
import ShareDialog from "./ShareDialog";
import {useHistory} from "react-router-dom";

function AllContests() {
  const [contests, setContests] = useState([])
  const [token, setToken] = useLocalStorage('token')
  const [sharing, setSharing] = useState(false)
  const [current,setCurrent] = useState('')
  const myID = jwt.decode(token).user.id

  function unixToStr(timestamp) {
    return (new Date(1000 * timestamp)).toLocaleString()
  }

  useEffect(() => {
    async function getContests() {
      const result = (await axios.get(`${baseServer}/contests/allContests`)).data
      setContests(result)
    }

    getContests()
  }, [])
  return (
    <Page>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Contest Name
            </TableCell>
            <TableCell>
              Start Time
            </TableCell>
            <TableCell>
              End Time
            </TableCell>
            <TableCell>
              Team Size Limit
            </TableCell>
            <TableCell>
              <Button onClick={() => {
                document.location = `${baseWebsite}/create`
              }} variant="contained">
                Create Contest
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sharing? <ShareDialog onClose={()=>{setSharing(false)}} open={sharing} contest={current}/>:null}
          {contests.map(elem => (
            <TableRow>
              <TableCell>
                {elem.title}
              </TableCell>
              <TableCell>
                {unixToStr(elem.date)}
              </TableCell>
              <TableCell>
                {unixToStr(elem.date + 60 * elem.minutes)}
              </TableCell>
              <TableCell>
                {elem.limit}
              </TableCell>
              <TableCell>
                {elem.creatorId === myID ||elem.collabs.includes(myID)?
                  <span>
                  <Button variant="contained" style={style} onClick={() => {
                    document.location = `${baseWebsite}/grading/:${elem._id}`
                  }}>
                    Grading Dashboard
                  </Button>
                  <TableCell>
                    <Button variant="contained" style={style} onClick={async () => {
                      await axios.post(`${baseServer}/contests/delete`,
                        {contestID: elem._id}, {headers: {token: token}})
                      window.location.reload()
                    }}>
                    Delete Contest
                  </Button>
                  </TableCell>
                    <TableCell>
                    <Button variant="contained" style={style} onClick={() => {
                      document.location = `${baseWebsite}/editContest/:${elem._id}`
                    }}>
                    Edit Contest
                  </Button>
                  </TableCell>
                    <TableCell>
                    <Button variant="contained" style={style} onClick={() => {
                      setSharing(true)
                      setCurrent(elem._id.toString())
                    }}>
                    Share
                  </Button>
                  </TableCell>
                    <TableCell>
                    <Button variant="contained" style={style} onClick={() => {
                      document.location = `${baseWebsite}/pdf/:${elem._id.toString()}`
                    }}>
                    Print
                  </Button>
                  </TableCell>
                    </span>
                  :
                  <Button variant="contained" style={style} onClick={() => {
                    document.location = `${baseWebsite}/contest/:${elem._id}`
                  }}>
                    Contest Page
                  </Button>
                }
              </TableCell>

            </TableRow>))}
        </TableBody>
      </Table>
    </Page>
  )
}

export default AllContests
