import axios from "axios";
import Page from "./Page";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Edit from "./Edit";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import AddCollab from "./AddCollab";
import React, {useEffect, useState} from "react";
import {baseServer} from "./utils";
import useLocalStorage from "./useLocalStorage";
import ViewProblem from "./ViewProblem";
import CollabList from "./CollabList";
import BackButton from "./BackButton";

function Access() {
    const [problems, setProblems] = useState([])
    const [adding, setAdding] = useState(false)
    const [changing, setChanging] = useState({})
    const [viewing, setViewing] = useState(false)
    const [token, setToken] = useLocalStorage('token')
    useEffect(async () => {
        const newProblems = (await axios.post(`${baseServer}/problems/myProblems`, {restrict: true}, {headers: {token: token}})).data
        setProblems(newProblems)
    }, [])
    return <Page>
        <BackButton/>
        <Dialog open={adding} onClose={() => {
            setAdding(false)
        }}>
            <DialogTitle>
                Add Friend as Collaborator
            </DialogTitle>
            <AddCollab style={{width: 400, height: 400}} onClose={() => {
                setAdding(false)
            }} problem={changing}/>
        </Dialog>
        <Dialog open={viewing} onClose={() => {
            setViewing(false)
        }}>
            <div style={{padding:80}}>
                <DialogTitle>
                    Collaborators
                </DialogTitle>
                <CollabList problem={changing}/>
            </div>
        </Dialog>
        <Table>
            {problems.map(elem =>
                <TableRow>
                    <TableCell>
                        {elem.title}
                    </TableCell>
                    <TableCell>
                        <Button onClick={() => {
                            setAdding(true)
                            setChanging(elem)
                        }}>
                            Add collaborators
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button onClick={() => {
                            setViewing(true)
                            setChanging(elem)
                        }}>
                            View Collaborator List
                        </Button>
                    </TableCell>
                </TableRow>
            )}
        </Table>
    </Page>
}

export default Access
