import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {baseWebsite, style} from "./utils";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

function AddProblem({onClose, myProbs}) {
  const [weight, setWeight] = useState('')
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [title,setTitle] = useState('')

  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }

  const padStyle = {
    padding: 20
  }
  const paperStyle = {
    width: 300,
    height: 40
  }
  const pad10={padding:10}

  const selected = {
    width: 300,
    height: 40,
    backgroundColor:'#77F'
  }
  return (
    <div style={center}>
      <div style={padStyle}>
        Search For a Problem
        <input type="text" onChange={event => {
          setSearch(event.target.value)
        }} value={search}/>
        <div style={padStyle}>
          {myProbs.filter(elem=>(elem.title.toLowerCase().includes(search.toLowerCase()))&&elem.privacy).slice(0,7).map(elem=>(
            <Grid style={pad10}>
              <Paper style={paperStyle}>
                <Button style={!(elem._id.toString()===id.toString())?paperStyle:selected} onClick={() => {
                  setId(elem._id)
                  setTitle(elem.title)
                }}>
                  {elem.title} {new Date(elem.date * 1000).toLocaleString()}
                </Button>
              </Paper>
            </Grid>))}
        </div>
        <TextField label={'Point Weight'} onChange={event => {setWeight(event.target.value)}} value={weight}/>
        <div style={padStyle}>
          <Button style={style} variant="contained" onClick={()=>{
            if(weight&&id) {
              onClose(id, weight, title)
            }
          }}>
            Add to Contest
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddProblem
