import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Page from "./Page";
import Button from "@material-ui/core/Button";
import useLocalStorage from "./useLocalStorage";

function AllSeries() {
  const [series, setSeries] = useState([])
  const [token, setToken] = useLocalStorage('token', '')
  useEffect(() => {
    async function getSeries() {
      const result = (await axios.get(`${baseServer}/series/allSeries`, {headers: {token: token}})).data
      console.log(result)
      setSeries(result)
    }

    getSeries()
  }, [])
  return (
    <Page>
      <div style={({padding:50})}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Series Name
              </TableCell>
              <TableCell>
                Team Size Limit
              </TableCell>
              <TableCell>
                <Button onClick={() => {
                  document.location = `${baseWebsite}/createSeries`
                }} variant="contained">
                  Create Series
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {series.map(elem => (
              <TableRow>
                <TableCell>
                  {elem.title}
                </TableCell>
                <TableCell>
                  {elem.limit}
                </TableCell>
                <TableCell>
                  <Button variant="contained" style={style} onClick={() => {
                    document.location = `${baseWebsite}/series/:${elem._id}`
                  }}>
                    Series Page
                  </Button>
                </TableCell>
              </TableRow>))}
          </TableBody>
        </Table>
      </div>
    </Page>
  )
}

export default AllSeries