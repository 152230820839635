import React, {useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

function Signup() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email,setEmail] = useState('')
  const [loginFail, setLoginFail] = useState(false)
  const [emailFail, setEmailFail] = useState(false)
  const [fieldFail,setFieldFail] = useState(false)

  const padStyle = {
    padding: 20
  }
  const mainStyle = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const fontStyle = {
    fontStyle:'Georgia'
  }
  return (
    <div style={mainStyle}>
      <div style={fontStyle}>
        Signup
      </div>
        <div style={padStyle}>
            <TextField style={{width:300}} label={'Email'} type="text" onChange={event => {
                setEmail(event.target.value)
            }} value={email}/>
        </div>
      <div style={padStyle}>
        <TextField label={'Username'} type="text" onChange={event => {
          setUsername(event.target.value)
        }} value={username}/>
      </div>
      <div style={padStyle}>
        <TextField label={'Password'} type="password" onChange={event => {
          setPassword(event.target.value)
        }} value={password}/>
      </div>
      <div style={padStyle}>
        <Button style={style} variant={'contained'} onClick={async () => {
          if (email && username && password) {
            setFieldFail(false)
            const newTok = (await axios.post(`${baseServer}/user/signup`, {
              username: username,
              password: password,
              email: email
            })).data
            if (!newTok.error) {
              document.location = baseWebsite + '/signedUp'
            } else if (newTok.error === 'user') {
              setLoginFail(true)
            } else if (newTok.error === 'email') {
              setEmailFail(true)
            }
          } else {
            setFieldFail(true)
          }
        }}>
          Sign Up
        </Button>
      </div>
      <div>
        {loginFail?'This username is already assigned':null}
      </div>
      <div>
        {emailFail?'This email is already assigned':null}
      </div>
      <div>
        {fieldFail?'Please fill out all 3 fields':null}
      </div>
    </div>
  );
}

export default Signup;
