import React, {useState, useEffect} from "react";
import axios from 'axios'
import {baseServer, baseWebsite, contestTypes, style} from "./utils";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import NewProblem from "./NewProblem";
import useLocalStorage from "./useLocalStorage";
import AddProblem from "./AddProblem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Page from "./Page";
import DateTimePicker from "react-datetime-picker";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Sortable from "./Sortable";

function EditContest() {
  const [myProbs, setMyProbs] = useState([])
  const [probs, setProbs] = useState([])
  const [open, setOpen] = useState(false)
  const [token, setToken] = useLocalStorage('token', '')
  const [date, setDate] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const [players, setPlayers] = useState('')
  const [time, setTime] = useState('')
  const [title, setTitle] = useState('')
  const [mySeries, setMySeries] = useState([])
  const [series, setSeries] = useState({id: '', idx: -1})
  const [usingSeries, setUsingSeries] = useState(false)
  const [seriesStyle, setSeriesStyle] = useState([])
  const [type, setType] = useState(0)
  const [display, setDisplay] = useState('2')
  const padStyle = {
    padding: 20
  }
  const id = document.location.href.split('/:')[1]
  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const titleStyle = {fontSize: 35, padding: 20}
  const paperStyle = {
    width: 500, height: 50, margin: 'auto',
    textAlign: 'center',
    align: 'center', fontSize: 20
  }
  const plusStyle = {fontSize: 20}
  const areaStyle = {
    fontSize: 18,
    width: 300,
    textAlign: 'center',
    align: 'center'
  }

  function removeProblem(idx) {
    const copyProbs = JSON.parse(JSON.stringify(probs))
    console.log(probs)
    copyProbs.splice(idx, 1)
    setProbs(copyProbs)
    return true
  }

  function addProblem(id, weight, title) {
    console.log(probs)
    const copyProbs = JSON.parse(JSON.stringify(probs))
    copyProbs.push({
      id: id,
      weight: weight,
      title: title,
      num: copyProbs.length,
    })
    setProbs(copyProbs)
    setOpen(false)
  }


  useEffect(() => {
    async function getProblems() {
      const thisContest = (await axios.post(`${baseServer}/contests/findContest`,
        {contestId: id},
        {headers: {token: token}})).data
      console.log(thisContest)
      setProbs(thisContest.problems)
      setDate(thisContest.start*1000)
      setEnd(thisContest.end*1000)
      setType(thisContest.type)
      setTitle(thisContest.title)
      setPlayers(thisContest.limit)
      if (thisContest.type===1) {
        setDisplay(thisContest.display)
      }
      const newProblems = (await axios.post(`${baseServer}/problems/myProblems`, {}, {headers: {token: token}})).data
      setMyProbs(newProblems)
      const newSeries = (await axios.get(`${baseServer}/series/mySeries`, {headers: {token: token}})).data
      setMySeries(newSeries)
      setSeriesStyle(newSeries.map(elem => ({variant: 'contained'})))
    }

    getProblems()
  }, [])
  return (
    <Page style={center}>
      <div style={titleStyle}>
        Edit Contest
      </div>
      <div>
        Contest Name:
      </div>
      <textarea style={areaStyle} onChange={event => {
        setTitle(event.target.value)
      }} value={title}/>
      <div style={padStyle}>
        <div>
          Contest Start:
        </div>
        <DateTimePicker onChange={setDate} value={date}/>
      </div>
      <div style={padStyle}>
        <div>
          Contest End:
        </div>
        <DateTimePicker onChange={setEnd} value={end}/>
      </div>
      <div style={padStyle}>
        <TextField label={'Team Size Limit'} onChange={event => {
          setPlayers(event.target.value)
        }} style={{width: 300}}
                   value={players}/>
      </div>
      <div style={padStyle}>
        <FormControl component="fieldset">
          <FormLabel color={"#000"} component="legend">Contest Type</FormLabel>
          <RadioGroup aria-label="" name="" value={type} onChange={event => {
            setType(JSON.parse(event.target.value))
          }}>
            {contestTypes.map((elem, idx) =>
              <FormControlLabel style={({padding: 5})} value={idx} control={<Radio color="primary"/>} label={elem}/>
            )
            }
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        Problems:
      </div>

      <Sortable items={probs} setItems={setProbs} destroy={removeProblem}/>
      <div style={padStyle}>
        <Fab style={plusStyle} color={"#000055"} aria-label="add" onClick={() => {
          setOpen(true)
        }}>
          +
        </Fab>
        <Dialog open={open} onClose={() => {
          setOpen(false)
        }}>
          <DialogTitle>
            Add a Problem
          </DialogTitle>
          <AddProblem onClose={addProblem} myProbs={myProbs}/>
        </Dialog>
        <div style={padStyle}/>
        <div style={padStyle}>
          {
            type === 1 ?
              <div>
                <div style={padStyle}>
                  <TextField label={'Problems Viewed At Once'} onChange={event => {
                    setDisplay(event.target.value)
                  }} value={display}/>
                </div>
              </div> : null
          }
        </div>
        <div style={padStyle}>
          <Button style={({...style, width: 300})} variant="contained" onClick={async () => {
            if (probs.length) {
              await axios.post(`${baseServer}/contests/editContest`, {
                id:id,
                problems: probs,
                time: (end - date) / 60000,
                players: players,
                date: date / 1000,
                title: title,
                type: type,
                display: JSON.parse(display)
              }, {headers: {token: token}})
              document.location = `${baseWebsite}/allContests`
            }
          }}>
            Modify Contest
          </Button>
        </div>
      </div>
    </Page>
  )
}

export default EditContest
