import {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import {TextField, Button} from "@material-ui/core";

function Forgot() {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const pad = {padding:30}

  return <div style={{
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }}>

    {submitted ? <div>
        Check email for further instructions, make sure to check the spam folder
      </div> :
      <div>
        <div style={pad}>
          Enter Your Email Here
        </div>
        <div style={pad}>
          <TextField variant="outlined" style={{width:300}} type="text" onChange={event => {
            setEmail(event.target.value)
          }}/>
        </div>
        <div style={pad}>
          <Button style={style} onClick={async () => {
            await axios.post(`${baseServer}/user/forgot`, {email: email})
            console.log('hi')
            setSubmitted(true)
          }}>
            submit
          </Button>
        </div>
      </div>
    }
  </div>
}

export default Forgot
