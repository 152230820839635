import React, {useEffect, useState} from "react";
import {Paper, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {baseServer, style} from "./utils";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import useLocalStorage from "./useLocalStorage";

function AddCollab({problem, onClose}) {
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState('')
    const [editor,setEditor] = useState(false)
    const [token,setToken] = useLocalStorage('token')
    const [user,setUser] = useLocalStorage('user')
    useEffect(async () => {
        const idList = (await axios.post(`${baseServer}/social/friends`,{problem:problem._id.toString()},{headers:{token:token}})).data
        setUsers(idList)
    }, [])
    const isAllowed = (elem) => {
        return elem.username.includes(search)&&problem.collab.findIndex(col=>col.id===elem.id)<0
            &&elem.username!==user
    }
    return <div>
        <div style={{padding: 40,fontSize:20}}>
            {problem.title}
        </div>
        <div style={{padding: 30,align:'center'}}>
            <TextField label={'Search for a User'} onChange={event => {
                setSearch(event.target.value)
            }} value={search}/>
        </div>
        <div style={{padding: 20}}>
            {
                users.filter(elem=>isAllowed(elem)).slice(0, 5).map(user => (
                    <div style={{padding: 20}}>
                        <div>
                            <Button fullWidth={true} style={selected===user.id?{...style,padding:30}:{padding: 30}} onClick={() => {
                                setSelected(user.id)
                            }}>
                                {user.username}
                            </Button>
                        </div>
                    </div>
                ))
            }
        </div>
        <div style={{textAlign:'center'}}>
            {users.filter(elem => isAllowed(elem)).length>5?
                <div>
                ({users.filter(elem => isAllowed(elem)).length-5} More Row
                    {users.filter(elem => isAllowed(elem)).length>6?'s':null})
                </div>:null}
        </div>
        <hr/>
        <div style={{padding:40}}>
            <FormControl component="fieldset">
                <FormLabel color={"#000"} component="legend">Permission Level</FormLabel>
                <RadioGroup aria-label="" name="" value={JSON.stringify(editor)} onChange={event => {
                    setEditor(JSON.parse(event.target.value))
                }}>
                    <FormControlLabel value="false" control={<Radio color="primary"/>} label="Viewer"/>
                    <FormControlLabel value="true" control={<Radio color="primary"/>} label="Editor"/>
                </RadioGroup>
            </FormControl>
        </div>
        <div style={{padding:40}}>
            <Button style={style} onClick={async ()=>{
                await axios.post(`${baseServer}/problems/addCollab`,{id:problem._id,userID:selected,editor:editor},
                    {headers:{token:token}})
                onClose()
            }}>
                Add
            </Button>
        </div>
    </div>
}

export default AddCollab
