import Latex from "react-latex";
import React from "react";
//export const baseWebsite = 'http://localhost:3000/#'
//export const baseServer = 'http://localhost:4040/api'
export const baseWebsite = 'https://threadpage.com/#'
export const baseServer = 'https://threadpage.com/api'
export const style = {backgroundColor:'#555555',color:'#FFF'}
export const center = {
  margin: 'auto',
  textAlign: 'center',
  align: 'center'
}

export function compile(someText,s) {
    if (someText) {
        let splitten = someText.split('\n')
        for (let idx = 0; idx<splitten.length; idx++){
            const line = splitten[idx]
            let count = 0
            for (let char of line){
                if(char==='$'){
                    count++
                }
            }
            if (count%2){
                splitten[idx]+='$'
                if (idx<splitten.length-1){
                    splitten[idx+1] = '$'+splitten[idx+1]
                }
            }
        }
        return <div>{
        splitten.map((elem) => elem.length ?
            <div style={s}>
                <Latex style={{fontFamily: 'sans-serif'}}>{elem}</Latex>
            </div> : <div style={{height:0}}/>)}
        </div>
    } else {return null}
}

export const contestTypes = ['Conventional','Marathon','Relay']
