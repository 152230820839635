import React, {useState} from "react";
import useLocalStorage from "./useLocalStorage";
import Page from "./Page";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {baseServer, baseWebsite, style} from "./utils";
import axios from "axios";

function CreateSeries() {
  const [title, setTitle] = useState('')
  const [limit,setLimit] = useState('')
  const [token, setToken] = useLocalStorage('token', '')
  const titleStyle = {fontSize: 30, padding: 15}
  const padStyle = {
    padding: 20
  }
  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  return (
    <Page>
      <div style={center}>
        <div style={titleStyle}>
          New Series
        </div>
        <div style={padStyle}>
          <TextField label={'Title'} onChange={event => setTitle(event.target.value)} value={title}/>
        </div>
      </div>
      <div style={padStyle}>
        <div style={center}>
          <div style={padStyle}>
            <TextField style={({textAlign:'center',width:150,align:'center'})} variant="outlined" label={'Team Size Limit'} onChange={event => setLimit(event.target.value)} value={limit}/>
          </div>
        </div>
      </div>
      <div style={({...padStyle, ...center})}>
        <Button style={style} onClick={async () => {
          await axios.post(`${baseServer}/series/create`, {title: title,limit:JSON.parse(limit)}, {headers: {token: token}})
          document.location = `${baseWebsite}/allSeries`
        }}>
          Create
        </Button>
      </div>
    </Page>
  )
}

export default CreateSeries