import React, {useState, useEffect} from "react";
import axios from "axios";
import {baseServer, baseWebsite} from "./utils";
import useLocalStorage from "./useLocalStorage";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Page from "./Page";
import TextField from "@material-ui/core/TextField";

function PublicProblems() {
    const [problems, setProblems] = useState([])
    const [token, setToken] = useLocalStorage('token', '')
    const [search, setSearch] = useState('')
    useEffect(() => {
        async function getProblems() {
            const newProblems = (await axios.get(`${baseServer}/problems/publicProblems`, {headers: {token: token}})).data
            setProblems(newProblems)
        }

        getProblems()
    }, [])
    const paperStyle = {
        width: 400,
        height: 50
    }
    const padStyle = {
        padding: 20
    }
    const center = {
        margin: 'auto',
        textAlign: 'center',
        align: 'center'
    }
    return (
        <Page>
            <div style={center}>
                <div style={{padding:20}}>
                    <TextField variant="outlined" value={search} onChange={event => {
                        setSearch(event.target.value)
                    }}/>
                </div>
            </div>
            <Grid item xs={10} style={center}>
                <Grid container justify="center" spacing={5} style={padStyle}>
                    {
                        problems.filter(elem => elem.title.toLowerCase().includes(search.toLowerCase())
                            || elem.creator.toLowerCase().includes(search.toLowerCase()))
                            .map(elem => (
                                <Grid style={padStyle}>
                                    <Paper style={paperStyle}>
                                        <Button style={paperStyle} onClick={() => {
                                            document.location = `${baseWebsite}/viewProblem/:${elem._id}`
                                        }}>
                                            {elem.title} ({elem.creator})
                                        </Button>
                                    </Paper>
                                </Grid>
                            ))
                    }
                </Grid>
            </Grid>
        </Page>
    )
}

export default PublicProblems
