import './App.css';
import React, {useCallback, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite, compile, style} from "./utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Latex from 'react-latex'
import {useDropzone} from "react-dropzone";

function NewProblem({onClose}) {
  const [text, setText] = useState('')
  const [answer, setAnswer] = useState('')
  const [privacy, setPrivacy] = useState(true)
  const [token, setToken] = useLocalStorage('token', '')
  const [title, setTitle] = useState('')
  const [img,setImg] = useState('')
  const [sol,setSol] = useState('')

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const data64 = reader.result
        const image = document.createElement('img');
        image.onload = async function() {
          // We create a canvas and get its context.
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // We set the dimensions at the wanted size.
          canvas.width = 120;
          canvas.height = 80;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0, 120, 80);
          console.log(canvas)
          const dataURI = canvas.toDataURL();
          setImg(dataURI)
        }
        image.src = data64.toString()
      }
      reader.readAsDataURL(file)
    })

  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const problemStyle = {
    width: 500,
    height: 200,
    fontSize: 20,
    borderRadius: 10
  }
  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const padStyle = {
    padding: 20
  }
  const titleStyle = {
    fontSize: 16,
    height: 20,
    width: 300
  }
  const imgStyle = {
    height:300,
    width:500
  }
  return (
    <div>
      <div style={center}>
        <div style={padStyle}>
          <div>
            Title:
          </div>
          <textarea style={titleStyle} onChange={event => {
            setTitle(event.target.value)
          }} value={title}/>
        </div>
        <div style={padStyle}>
          <FormControl component="fieldset">
            <FormLabel color={"#000"} component="legend">Visibility</FormLabel>
            <RadioGroup aria-label="" name="" value={JSON.stringify(privacy)} onChange={event => {
              setPrivacy(JSON.parse(event.target.value))
            }}>
              <FormControlLabel value="false" control={<Radio color="primary"/>} label="Public"/>
              <FormControlLabel value="true" control={<Radio color="primary"/>} label="Private"/>
            </RadioGroup>
          </FormControl>
        </div>
        <div style={padStyle}>
          <div>
            Preview:
          </div>
          <div>
            <div style={center}>
              {compile(text,center)}
            </div>
          </div>
        </div>
        <div style={padStyle}>
          <div>
            Problem Statement:
          </div>
          <textarea style={problemStyle} onChange={event => {
            setText(event.target.value)
          }} value={text}/>
        </div>
        <div style={padStyle}>
          <div style={padStyle}>
            Insert Image (optional):
          </div>
          <div style={padStyle}>
          {img.length?<img style={imgStyle} src={img} alt="preview"/>:null}
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button style={style}>Click to select image</Button>
          </div>
        </div>
        <div style={padStyle}>
          <div>
            Answer Preview:
          </div>
          <div>
            <div style={center}>
              <Latex style={{fontFamily:'sans-serif'}}>{'$'+answer+'$'}</Latex>
            </div>
          </div>
        </div>
        <div style={padStyle}>
          <TextField label={'Answer'} onChange={event => {
            setAnswer(event.target.value)
          }} value={answer}/>
        </div>
        <div style={padStyle}>
          <div>
            Solution Preview:
          </div>
          <div>
            {compile(sol,center)}
          </div>
        </div>
        <div style={padStyle}>
          <div>
            Solution:
          </div>
          <textarea style={problemStyle} onChange={event => {
            setSol(event.target.value)
          }} value={sol}/>
        </div>
        <div style={padStyle}>
          <Button variant="contained" onClick={async () => {
            const payload = {submittable: true, privacy: privacy, answer: answer, text: text, title: title,img:img,sol:sol}
            await axios.post(`${baseServer}/problems/newProblem/`, payload, {headers: {token: token}})
            onClose()
            document.location = baseWebsite
            window.location.reload()
          }}>
            Publish
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewProblem
