import React, {useEffect, useState} from 'react';
import {baseServer, compile, style} from "./utils";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

function ContestPdf() {
  const utc = require('dayjs/plugin/utc')
  const timezone = require('dayjs/plugin/timezone')
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const tz= dayjs.tz.guess()
  const getLocalDateObject = (unix)=>{
    const d =  dayjs(unix*1000)
    return d.tz(tz).$d
  }
  const problemStyle = {
    fontSize: 20,
    fontFamily: 'serif',
    pageBreakInside:'avoid',
  }
  const padStyle = {padding: 10}
  const [contest,setContest] = useState({})
  const [token,setToken] = useLocalStorage('token')
  const id = document.location.href.split('/:')[1]
  useEffect(()=>{
    if (!contest.problems) {
      (async () => {
        const result = await axios.post(`${baseServer}/contests/getContest`, {contestId: id},
          {headers: {token: token}})
        setContest(result.data)
      })()
    }else {
      window.print()
    }
  },[contest])
  return (
    <div style={{padding:50}}>
      <div style={{fontFamily:"serif",fontSize:40,padding:20,margin:'auto',textAlign:'center'}}>
        {contest.title}
      </div>
      <div style={{fontFamily:"serif",fontSize:20,padding:20,margin:'auto',textAlign:'center'}}>
        {contest?getLocalDateObject(contest.time).toString().split(":")[0].slice(0,-2):null}
      </div>
      {contest.problems ? contest.problems.map((elem, idx) =>
        <div>
          {elem.text ? <div style={padStyle}>
            {contest.type?<hr style={{borderTop:'border-top: 3px dotted #000'}}/>:null}
            <div style={padStyle}>
              <div style={problemStyle}>
                {compile(idx + 1 + '. ' + elem.text, {})}
                <div style={padStyle}>
                  {elem.img ? <img style={{height: 300, width: 500}} src={elem.img}
                                   alt="preview"/> : null}
                </div>
              </div>
            </div>
          </div> : null}
        </div>):null
      }
    </div>
  )
}

export default ContestPdf
