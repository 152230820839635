import React from "react";
import Button from "@material-ui/core/Button";
import {style} from "./utils";

function BackButton() {
  const padStyle = {padding:20}
  return(
    <div style={padStyle}>
      <Button variant="contained" style={style} onClick={()=>{window.history.go(-1)}}>
        {'< Back <'}
      </Button>
    </div>
  )
}

export default BackButton