import React, {useEffect, useState} from "react";
import Page from "./Page";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import useLocalStorage from "./useLocalStorage";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

function GradingDashboard() {
  const [teams, setTeams] = useState([])
  const [token, setToken] = useLocalStorage('token')
  const [teamName,setTeamName] = useLocalStorage('team')
  const getId = url => url.split('/:')[1]
  const contestID = getId(document.location.href)
  const center = {
    margin: 'auto',
    textAlign: 'center'
  }
  useEffect(async () => {
    const roster = await axios.post(`${baseServer}/contests/teams`, {contestID: contestID}, {headers: {token: token}})
    setTeams(roster.data)
  }, [])
  return (
    <Page>
      <div style={{padding: 20}}>
        <Button onClick={() => {
          document.location = `${baseWebsite}/results/:${contestID}`
        }}>
          Current Standings
        </Button>
      </div>
      <div style={{padding: 20}}>
        Select a Team to Grade
      </div>
      {teams.map(elem => <div style={{...center, padding: 15}}>
        <Divider/>
        <div style={{padding: 10}}>
          <Button variant="contained" style={style} onClick={() => {
            setTeamName(elem)
            document.location = `${baseWebsite}/teamAnswers/:${contestID}/:/`
          }}>
            {elem}
          </Button>
        </div>
      </div>)}
    </Page>
  )
}

export default GradingDashboard
