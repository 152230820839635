import Page from "./Page";
import {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import {TextField, Button} from "@material-ui/core";

function ChangePass() {
  const code = document.location.href.split('/:')[2]
  const email = document.location.href.split('/:')[1]
  const [token, setToken] = useLocalStorage('token')
  const [user, setUser] = useLocalStorage('user')
  const [pass, setPass] = useState('')
  const pad = {padding:30}
  return <div style={{
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }}>
    <div style={pad}>
      Enter Your New Password Here
    </div>
    <div style={pad}>
      <TextField variant="outlined" type="password" onChange={event => {
        setPass(event.target.value)
      }}/>
    </div>
    <div style={pad}>
      <Button style={style} onClick={async () => {
        const res = (await axios.post(`${baseServer}/user/changePass`, {email: email, code: code, pass})).data
        if (res.token) {
          setToken(res.token)
          setUser(res.username)
          document.location = baseWebsite
        }
      }}>
        submit
      </Button>
    </div>
  </div>
}

export default ChangePass
