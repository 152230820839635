import React, {useState, useEffect} from "react";
import useLocalStorage from "./useLocalStorage";
import axios from "axios";
import {baseServer, baseWebsite, style} from "./utils";
import Page from "./Page";
import BackButton from "./BackButton";
import dayjs from "dayjs";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Latex from "react-latex";
import jwt from "jsonwebtoken";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import ShareDialog from "./ShareDialog";
import SeriesShareDialog from "./SeriesShareDialog";

function SeriesPage() {
  const [series, setSeries] = useState({})
  const [exc, setExc] = useState(false)
  const [token, setToken] = useLocalStorage('token', '')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [tick, setTick] = useState(0)
  const [sharing, setSharing] = useState(false)
  const [current,setCurrent] = useState('')
  const id = document.location.href.split('/:')[1]
  const padStyle = {padding: 20}
  const center = {
    margin: 'auto',
    textAlign: 'center',
    align: 'center'
  }
  const titleStyle = {
    fontSize: 40,
    padding: 20,
  }
  const large = {fontSize: 25}
  const nameStyle = {fontSize: 32, fontFamily: 'serif'}


  useEffect(() => {
    async function getSeries() {
      if (!exc) {
        const result = (await axios.post(`${baseServer}/series/getSeries`, {seriesId: id}, {headers: {token: token}})).data
        setSeries(result)
      }
    }

    getSeries()
    setTimeout(() => {
      setTick(tick + 1)
    }, 1000)
  }, [tick])
  return (
    <Page>
      <BackButton/>
      <div style={titleStyle}>
        {series.title}
      </div>
      <div style={center}>
        <div style={nameStyle}>
          {series.team ? series.team.name : null}
        </div>
      </div>

      {
        !series.team && series.title &&!series.isOwner ?
          <div style={center}>
            <div style={padStyle}>
              <TextField label={'Team Name'} onChange={event => {
                setName(event.target.value)
              }} value={name}/>
            </div>
            <div style={padStyle}>
              <Button variant="contained" style={style} onClick={async () => {
                await axios.post(`${baseServer}/series/createTeam`, {
                  seriesId: id,
                  teamName: name
                }, {headers: {token: token}})
                window.location.reload()
              }}>
                Create Team
              </Button>
            </div>
            <Divider/>
            <div style={padStyle}>
              <TextField label={'Enter Team Code'} onChange={event => {
                setCode(event.target.value)
              }} value={code}/>
            </div>
            <div style={padStyle}>
              <Button variant="contained" style={style} onClick={async () => {
                const res = await axios.post(`${baseServer}/series/joinTeam`, {
                  code: code,
                  seriesId: id
                }, {headers: {token: token}})
                if (res.data === 'limit') {
                  console.log('too many ')
                  setSeries({title: 'Team Size Limit Exceeded'})
                  setExc(true)
                } else {
                  window.location.reload()
                }
              }}>
                Join Team
              </Button>
            </div>
            <Button onClick={() => {
              document.location = `${baseWebsite}/seriesResults/:${id}`
            }}>
              Series Results
            </Button>
          </div> : series.team ?
            <div style={center}>
              <div style={({...large, ...center, ...padStyle})}>
                Your Team Code: {series.team.code}
              </div>
              <div style={({...padStyle, fontSize: 20})}>
                Contests:
              </div>
              {series.contests.map((contest, idx) => (
                <div style={padStyle}>
                  <Button style={({...style, width: 300, fontSize: 20})} onClick={() => {
                    document.location = `${baseWebsite}/contest/:${contest.id}`
                  }}>
                    {idx + 1}. {contest.name}
                  </Button>
                </div>
              ))}
              <div style={padStyle}>
                <Button onClick={() => {
                  document.location = `${baseWebsite}/seriesResults/:${id}`
                }}>
                  Series Results
                </Button>
              </div>
            </div>
            : series.isOwner ?
              <div style={center}>
                <div style={padStyle}>
                  <Button onClick={() => {
                    document.location = `${baseWebsite}/seriesResults/:${id}`
                  }}>
                    Series Results
                  </Button>
                </div>
                <div style={padStyle}>
                    <Button variant="contained" style={style} onClick={() => {
                      setSharing(true)
                    }}>
                      Share
                    </Button>
                </div>
                {sharing? <SeriesShareDialog onClose={()=>{setSharing(false)}} open={sharing} series={id}/>:null}
                <Table>
                  <TableBody>
                    {series.contests.map((contest, idx) => (
                      <TableRow>
                        <TableCell>{contest.name}</TableCell>
                        <TableCell>
                          <Button variant="contained" style={style} onClick={() => {
                            document.location = `${baseWebsite}/grading/:${contest.id}`
                          }}>
                            Grading Dashboard
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" style={style} onClick={async () => {
                            await axios.post(`${baseServer}/contests/delete`,
                              {contestID: contest.id}, {headers: {token: token}})
                            window.location.reload()
                          }}>
                            Delete Contest
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" style={style} onClick={() => {
                            document.location = `${baseWebsite}/editContest/:${contest.id}`
                          }}>
                            Edit Contest
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button variant="contained" style={style} onClick={() => {
                            document.location = `${baseWebsite}/pdf/:${contest.id}`
                          }}>
                            Print
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              : null
      }
    </Page>
  )
}

export default SeriesPage
