import React, {useState, useEffect} from 'react'
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import axios from "axios";
import {baseServer} from "./utils";
import useLocalStorage from "./useLocalStorage";
import Page from "./Page";
import BackButton from "./BackButton";

function Results() {
  const [leaderboard, setLeaderboard] = useState([])
  const [token, setToken] = useLocalStorage('token', '')
  const [myIdx,setMyIdx] = useState(-1)
  const id = document.location.href.split('/:')[1]
  useEffect(() => {
    async function getResults() {
      const res = (await axios.post(`${baseServer}/contests/results`, {id: id}, {headers: {token: token}})).data
      setLeaderboard(res.lb)
      setMyIdx(res.myIdx)
    }

    getResults()
  }, [])
  return (
    <Page>
      <BackButton/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>
              Team
            </TableCell>
            {leaderboard[0]?
              leaderboard[0].slice(2).map((elem,idx)=>
              <TableCell>
                {idx+1}
              </TableCell>
              ):null
            }
            <TableCell>
              Total Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            leaderboard.map((row, idx) =>
              <TableRow>
                <TableCell style={idx===myIdx?{fontWeight:700}:{}}>
                  {leaderboard.findIndex(elem=>row[row.length-1]===elem[row.length-1]) + 1}.
                </TableCell>
                {
                  row.map(elem =>
                    <TableCell style={idx===myIdx?{fontWeight:700}:{}}>
                      {elem}
                    </TableCell>
                  )
                }
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Page>
  )
}

export default Results
