import React from "react";
import Page from "./Page";
import Latex from "react-latex";

function Info() {
    const titleStyle = {
        fontFamily:'serif',
        fontSize:32,
        padding:50
    }

    const infoStyle = {
        fontFamily:'serif',
        fontSize:22,
        padding:50
    }

    const liStyle = {padding:10}
    return (
        <Page>
            <div style={titleStyle}>
                Users' Guide
            </div>
            <a style={{padding:50}} href="https://math-on-quora.surge.sh/">LaTeX Literature, Recommended By Shadman</a>
            <div style={infoStyle}>
                Writing problems:
                <ol>
                    <li style={liStyle}>Open the "My Collection" tab</li>
                    <li style={liStyle}>Select the plus icon</li>
                    <li style={liStyle}>Give your problem a title</li>
                    <li style={liStyle}>Choose a visibility: public or private, public problems will be visible to other users in the
                        "Public Problems" bulletin. Private problems can be added to contests, and shared with collaborators
                        if you wish.</li>
                    <li style={liStyle}>Write your problem statement. If you want to use equations and symbols,
                    you can use LaTeX notation by surrounding the expression with the $ character on either side.
                        <ul>
                            <li style={liStyle}>
                                Example: Solve the equation $x^2-3=0$ for all positive values of $x$.
                                <div/>
                                <Latex style={{fontFamily:'sans-serif',padding:50,fontSize:20}}>
                                    Solve the equation $x^2-3=0$ for all positive values of $x$.
                                </Latex>
                            </li>
                        </ul>
                    </li>
                    <li style={liStyle}>Write an answer for your problem. LaTeX can be used for answers as well,
                        but should not be surrounded by $ characters, as the whole answer is assumed to be a mathematical
                        expression.
                        <ul>
                            <li style={liStyle}>
                                Example: {'\\sqrt{3}'}
                                <div/>
                                <Latex style={{fontFamily:'sans-serif',padding:50,fontSize:20}}>
                                    {' $\\sqrt{3}$'}
                                </Latex>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Publish the problem
                    </li>
                </ol>
            </div>
        </Page>
    )
}

export default Info
